import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  checkRequiredFields(event) {
    this.element.querySelectorAll('form').forEach((form) => {
      if (form.reportValidity() == false) {
        event.preventDefault();
      }
    });
  }
}
