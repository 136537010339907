import { Controller } from '@hotwired/stimulus';
import Rails from '@rails/ujs';

export default class extends Controller {
  connect() {
    this.element.addEventListener('ajax:success', (event) => {
      document.body.dispatchEvent(
        new CustomEvent('ajax:success', {
          detail: [{ message: event.detail[0].message }]
        })
      );
    });
  }

  submit() {
    Rails.fire(this.element, 'submit');
  }
}
