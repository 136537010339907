import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['button'];

  buttonTargetConnected(buttonElement) {
    buttonElement.addEventListener('click', () => {
      this.uncheckRadios();
      this.checkRadio(buttonElement);
    });
  }

  uncheckRadios() {
    this.buttonTargets.forEach((buttonElement) => {
      buttonElement.querySelector('input').checked = false;
      buttonElement.classList.remove('toggle-active');
    });
  }

  checkRadio(buttonElement) {
    buttonElement.querySelector('input').checked = true;
    buttonElement.classList.add('toggle-active');
  }
}
