import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['ratingField'];

  ratingFieldTargetConnected(ratingField) {
    ratingField
      .querySelector('label')
      .addEventListener('click', (clickedLabel) => {
        this.removeActiveLabels();
        this.addActiveLabels(ratingField);
      });
  }

  // private

  removeActiveLabels() {
    this.ratingFieldTargets.forEach((ratingField) => {
      const icon = ratingField.querySelector('label i');
      icon.classList.remove('fas');
      icon.classList.add('far');
    });
  }

  addActiveLabels(selectedRatingField) {
    const selectedRatingValue = selectedRatingField.dataset.value;
    this.ratingFieldTargets.forEach((ratingField) => {
      if (ratingField.dataset.value <= selectedRatingValue) {
        const icon = ratingField.querySelector('label i');
        icon.classList.remove('far');
        icon.classList.add('fas');
      }
    });
  }
}
