import { Controller } from '@hotwired/stimulus';
import { MDCDialog } from '@material/dialog';

export default class extends Controller {
  static targets = ['dialog', 'dialogContent', 'dialogTitle'];

  dialogTargetConnected() {
    this.dialog = new MDCDialog(this.dialogTarget);
  }

  open(event) {
    event.preventDefault();
    this.dialogContentTarget.innerHTML = event.params.text;
    this.dialogTitleTarget.innerHTML = event.params.title;
    this.dialog.open();
  }
}
