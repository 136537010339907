import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static values = {
    levels: Number,
    showInnovationMechanisms: String,
    strategyResearchBoxes: Number
  };

  filter(options) {
    if (
      (options.levels == null ||
        this.levelsValue === parseInt(options.levels)) &&
      (options.showInnovationMechanisms == null ||
        this.showInnovationMechanismsValue ===
          options.showInnovationMechanisms) &&
      (options.strategyResearchBoxes == null ||
        this.strategyResearchBoxesValue ===
          parseInt(options.strategyResearchBoxes))
    ) {
      this.show();
    } else {
      this.hide();
    }
  }

  hide() {
    this.element.classList.add('hide');
  }

  isHidden() {
    return this.element.classList.contains('hide');
  }

  show() {
    this.element.classList.remove('hide');
  }
}
