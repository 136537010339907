import { Controller } from '@hotwired/stimulus';
import Rails from '@rails/ujs';

export default class extends Controller {
  new(event) {
    this.request({
      url: event.params.url,
      type: 'get',
      success: (data) => {
        this.handleNewResponse(data.html);
      }
    });
  }

  create(event) {
    const form = event.target.closest('form');
    this.request({
      url: form.action,
      data: `${event.target.name}=${encodeURIComponent(event.target.value)}`,
      type: 'post',
      success: (data) => {
        this.handleSaveResponse(form, data.html);
      }
    });
  }

  update(event) {
    const form = event.target.closest('form');
    this.request({
      url: form.action,
      data: `${event.target.name}=${encodeURIComponent(event.target.value)}`,
      type: 'put',
      success: (data) => {
        this.handleSaveResponse(form, data.html);
      }
    });
  }

  // remove an input that hasn't been saved yet
  remove(event) {
    const form = document.getElementById(event.params.formId);
    // someone immediately clicked the remove button after entering text
    if (form.querySelector('input[type=text]').value != '') {
      return;
    }
    form.remove();
  }

  destroy(event) {
    const form = event.target.closest('form');
    this.request({
      url: form.action,
      type: 'delete',
      success: () => {
        this.handleDestroyResponse(event.params.formId);
      }
    });
  }

  // private

  request(options) {
    Rails.ajax({
      url: options.url,
      type: options.type,
      data: options.data,
      dataType: 'json',
      success: options.success
    });
  }

  handleNewResponse(html) {
    this.element.insertAdjacentHTML('beforeend', html);
  }

  handleSaveResponse(formElement, html) {
    formElement.outerHTML = html;
  }

  handleDestroyResponse(formId) {
    document.getElementById(formId).remove();
  }
}
