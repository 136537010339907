import { Controller } from '@hotwired/stimulus';
const html2canvas = require('html2canvas');

export default class extends Controller {
  static targets = ['dashboard'];

  dashboardScreenshotPng() {
    html2canvas(this.dashboardTarget).then((canvas) => {
      const base64img = canvas.toDataURL('image/png');
      let a = document.createElement('a');
      a.download = 'lists-dashboard.png';
      a.href = base64img;
      a.click();
    });
  }

  dashboardScreenshotJpeg() {
    html2canvas(this.dashboardTarget).then((canvas) => {
      const base64img = canvas.toDataURL('image/jpeg');
      let a = document.createElement('a');
      a.download = 'lists-dashboard.jpg';
      a.href = base64img;
      a.click();
    });
  }
}
