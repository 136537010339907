import { Controller } from '@hotwired/stimulus';
import { MDCDialog } from '@material/dialog';
import { MDCRadio } from '@material/radio';

export default class extends Controller {
  static outlets = ['structure-card'];

  static get targets() {
    return ['radio', 'card', 'magnify', 'example', 'empty', 'form'];
  }

  radioTargetConnected() {
    new MDCRadio(this.element);
    this.radioTargets.forEach((radio) => {
      let input = radio.getElementsByTagName('input')[0];
      input.addEventListener('change', () => {
        this.filterOptions();
      });
    });
  }

  filterOptions() {
    let options = {};
    this.radioTargets.forEach((radio, index) => {
      let input = radio.getElementsByTagName('input')[0];
      if (input.checked) {
        options[input.name.split('[')[1].split(']')[0]] = input.value;
      }
    });

    this.structureCardOutlets.forEach((structure) => {
      structure.filter(options);
    });

    const isHidden = (card) => card.isHidden();
    if (this.structureCardOutlets.every(isHidden)) {
      this.emptyTarget.classList.remove('hide');
    } else {
      this.emptyTarget.classList.add('hide');
    }
  }

  reset() {
    this.formTarget.reset();
    this.filterOptions();
  }

  magnifyTargetConnected() {
    const fsDialog = new MDCDialog(
      document.querySelector('.mdc-dialog--fullscreen')
    );
    const fsDialogContent = document.getElementById(
      'fullscreen-dialog-content'
    );
    this.magnifyTargets.forEach((button) => {
      button.addEventListener('click', () => {
        fsDialogContent.innerHTML = `<image src=${button.dataset.magnify} style="width: 100%"/>`;
        fsDialog.open();
      });
    });
  }

  exampleTargetConnected() {
    const fsDialog = new MDCDialog(
      document.querySelector('.mdc-dialog--fullscreen')
    );
    const fsDialogContent = document.getElementById(
      'fullscreen-dialog-content'
    );
    this.exampleTargets.forEach((button) => {
      button.addEventListener('click', () => {
        fsDialogContent.innerHTML = `<image src=${button.dataset.example} style="width: 100%"/>`;
        fsDialog.open();
      });
    });
  }
}
