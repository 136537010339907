import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['otherRadio', 'radio'];

  otherRadioTargetConnected(radio) {
    radio.addEventListener('change', (event) => {
      this.hideTextFields();
      this.showTextField(event.target);
    });
  }

  radioTargetConnected(radio) {
    radio.addEventListener('change', (event) => {
      this.hideTextFields();
    });
  }

  showTextField(radio) {
    radio
      .closest('.radio-wrapper')
      .querySelector('.text-field-wrapper')
      .classList.remove('display-none');
  }

  hideTextFields() {
    this.element.parentElement
      .querySelectorAll('.text-field-wrapper')
      .forEach((wrapper) => {
        wrapper.classList.add('display-none');
        wrapper.querySelector('input[type="text"]').value = '';
      });
  }
}
