import { Controller } from '@hotwired/stimulus';
import { MDCDialog } from '@material/dialog';

export default class extends Controller {
  static targets = ['viewed', 'dialog'];

  dialogTargetConnected() {
    if (this.viewedTarget.dataset.value === 'false') {
      const dialog = new MDCDialog(this.dialogTarget);
      dialog.open();
    }
  }
}
