import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['checkbox', 'textfieldWrapper'];

  connect() {}

  checkboxTargetConnected(checkbox) {
    checkbox.addEventListener('change', (event) => {
      this.toggleTextfield(event.target.checked);
    });
  }

  toggleTextfield(shouldShow) {
    if (shouldShow) {
      this.textfieldWrapperTarget.classList.remove('display-none');
    } else {
      this.textfieldWrapperTarget.classList.add('display-none');
    }
  }
}
