import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['whyModified'];

  connect() {
    this.addProspectiveListener();
  }

  addProspectiveListener() {
    const prospectiveRadio = this.element.querySelector('[value="1"]');
    const notProspectiveRadio = this.element.querySelector('[value="0"]');
    prospectiveRadio.addEventListener('change', (event) => {
      this.handleProspectiveSelection(event.target.value);
    });
    notProspectiveRadio.addEventListener('change', (event) => {
      this.handleProspectiveSelection(event.target.value);
    });
  }

  handleProspectiveSelection(isProspective) {
    if (isProspective == '1') {
      this.whyModifiedTarget.classList.add('display-none');
    } else {
      this.whyModifiedTarget.classList.remove('display-none');
    }
  }
}
