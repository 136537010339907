import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['slide'];

  initialize() {
    this.currentSlideIndex = 0;
    this.slideCount = 0;
    this.slides = {};
  }

  slideTargetConnected(element) {
    const slideIndex = Number.parseInt(element.dataset.slideIndex);
    this.slides[slideIndex] = element;

    if (slideIndex + 1 > this.slideCount) {
      this.slideCount = slideIndex + 1;
    }
  }

  hideCurrentSlide() {
    this.slides[this.currentSlideIndex].classList.add('hide');
  }

  showCurrentSlide() {
    this.slides[this.currentSlideIndex].classList.remove('hide');
  }

  previous() {
    this.hideCurrentSlide();
    if (this.currentSlideIndex - 1 >= 0) {
      this.currentSlideIndex -= 1;
    } else {
      this.currentSlideIndex = this.slideCount - 1;
    }
    this.showCurrentSlide();
  }

  next() {
    this.hideCurrentSlide();
    if (this.currentSlideIndex + 1 < this.slideCount) {
      this.currentSlideIndex += 1;
    } else {
      this.currentSlideIndex = 0;
    }
    this.showCurrentSlide();
  }
}
