import { Controller } from '@hotwired/stimulus';
import Rails from '@rails/ujs';

export default class extends Controller {
  new(event) {
    Rails.ajax({
      url: event.params.url,
      type: 'get',
      dataType: 'json',
      success: (data) => {
        this.handleNewResponse(event.params.formGroupId, data.html);
      }
    });
  }

  create(event) {
    if (event.target.value.trim() == '') {
      return;
    }

    const form = event.target.closest('form');
    Rails.ajax({
      url: form.action,
      data: `${event.target.name}=${encodeURIComponent(event.target.value)}`,
      type: 'post',
      dataType: 'json',
      success: (data) => {
        this.handleSaveResponse(form, data.html);
      }
    });
  }

  update(event) {
    if (event.target.value.trim() == '') {
      return;
    }

    const form = event.target.closest('form');
    Rails.ajax({
      url: form.action,
      data: `${event.target.name}=${encodeURIComponent(event.target.value)}`,
      type: 'put',
      dataType: 'json',
      success: (data) => {
        this.handleSaveResponse(form, data.html);
      }
    });
  }

  // remove an input that hasn't been saved yet
  remove(event) {
    document.getElementById(event.params.formId).remove();
  }

  destroy(event) {
    Rails.ajax({
      url: event.params.url,
      type: 'delete',
      dataType: 'json',
      success: () => {
        this.handleDestroyResponse(event.params.formId);
      }
    });
  }

  // private

  handleNewResponse(formGroupId, html) {
    document.getElementById(formGroupId).insertAdjacentHTML('beforeend', html);
  }

  handleSaveResponse(formElement, html) {
    // Don't try to render update if DOM has changed.
    if (formElement.parentNode != null) {
      formElement.outerHTML = html;
    }
  }

  handleDestroyResponse(formId) {
    document.getElementById(formId).remove();
  }
}
