import { Controller } from '@hotwired/stimulus';
import Rails from '@rails/ujs';

export default class extends Controller {
  static targets = ['table', 'allButton', 'activeButton'];

  displayAll() {
    const url = `${this.tableTarget.dataset.url}?all=true`;

    Rails.ajax({
      url: url,
      type: 'GET',
      dataType: 'json',
      success: (data) => {
        this.tableTarget.innerHTML = data.html;
      }
    });

    this.activeButtonTarget.classList.remove('hide');
    this.allButtonTarget.classList.add('hide');
  }

  displayActive() {
    const url = this.tableTarget.dataset.url;

    Rails.ajax({
      url: url,
      type: 'GET',
      dataType: 'json',
      success: (data) => {
        this.tableTarget.innerHTML = data.html;
      }
    });

    this.activeButtonTarget.classList.add('hide');
    this.allButtonTarget.classList.remove('hide');
  }
}
