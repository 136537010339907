import * as Sentry from '@sentry/browser';
import { BrowserTracing } from '@sentry/tracing';

document.addEventListener('DOMContentLoaded', function () {
  const bodyData = document.querySelector('body').dataset;

  if (['staging', 'production'].includes(bodyData.environment)) {
    Sentry.init({
      dsn: bodyData['sentryDsn'],
      environment: bodyData['environment'],
      release: 'irlm ' + bodyData['version'],
      integrations: [Sentry.browserTracingIntegration()],
      tracesSampleRate: 0.1
    });

    Sentry.getCurrentScope().setUser({ id: bodyData['userId'] });
  }
});
