import { Controller } from '@hotwired/stimulus';
import Rails from '@rails/ujs';

export default class extends Controller {
  static targets = ['form', 'feedbackContent', 'dropdown'];

  formTargetConnected(form) {
    form.addEventListener('ajax:success', (event) => {
      this.handleSuccessfulSubmit(event.detail[0].html);
    });
  }

  handleSuccessfulSubmit(formHtml) {
    this.element.innerHTML = formHtml;
    const feedbackDialogController =
      this.application.getControllerForElementAndIdentifier(
        document.getElementById('feedback-dialog'),
        'mdc--dialog'
      );

    feedbackDialogController.dialog.close();
  }

  dropdownTargetConnected() {
    this.dropdownTarget.value = '';

    this.dropdownTarget.addEventListener('change', () => {
      this.feedbackContentTarget.classList.remove('hide');
    });
  }
}
