import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['bundle', 'strategy', 'dashboard'];

  connect() {
    this.fixOverlappingDateLabels();
    window.addEventListener('resize', (_event) => {
      this.strategyTargets.forEach(this.strategyTargetConnected);
      this.bundleTargets.forEach(this.bundleTargetConnected);
    });
  }

  dashboardTargetConnected() {
    this.dashboardTarget.style.height = `calc((${this.strategyTargets.length} * 40px) + (${this.bundleTargets.length} * 36px) + 315px)`;
  }

  strategyTargetConnected(element) {
    // position and size strategy
    const dayWidth = element
      .closest('.day-strategy')
      .getBoundingClientRect().width;
    element.style.left = `${element.dataset.dayOffset * dayWidth}px`;
    element.style.width = `${(element.dataset.dayCount / element.dataset.daysSlicedBy) * dayWidth}px`;
    // position strategy label
    const label = element.querySelector('.strategy-label');
    if (label) {
      const labelRect = label.getBoundingClientRect();
      if (labelRect.x < 0) {
        label.style['margin-left'] = '-50px';
      } else {
        const bodyWidth = document
          .querySelector('body')
          .getBoundingClientRect().width;
        if (labelRect.x + labelRect.width > bodyWidth) {
          label.style['margin-right'] = '-20px';
        }
      }
    }
  }

  bundleTargetConnected(element) {
    const dayWidth = element
      .closest('.day-strategy')
      .getBoundingClientRect().width;
    element.style.width = `${(element.dataset.dayCount / element.dataset.daysSlicedBy) * dayWidth}px`;
  }

  /* private */

  fixOverlappingDateLabels() {
    const singleOffsetInPixels = 20;
    const labels = document.querySelectorAll('.x-label');
    var cumulativeOffset = singleOffsetInPixels;
    for (let n = 1; n < labels.length; n++) {
      let e1 = labels[n - 1];
      let e2 = labels[n];

      if (this.checkOverlap(e1, e2)) {
        e2.style.top = `${e2.offsetTop + cumulativeOffset}px`;
        cumulativeOffset += singleOffsetInPixels;
      }
    }
  }

  checkOverlap(leftElement, rightElement) {
    const leftParentRectangle =
      leftElement.parentElement.getBoundingClientRect();
    const rightParentRectangle =
      rightElement.parentElement.getBoundingClientRect();

    return (
      rightParentRectangle.x <
      leftParentRectangle.x + leftElement.offsetHeight * Math.sqrt(2)
    );
  }
}
