import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  dateInput(e) {
    if (e.target.value) {
      e.target.classList.add('has-value');
    } else {
      e.target.classList.remove('has-value');
    }
  }
}
