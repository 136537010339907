import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['accidentalFields', 'justification'];

  accidentalStoppage() {
    this.accidentalFieldsTarget.classList.add('hide');
    this.justificationTarget.required = false;
  }

  notAccidentalStoppage() {
    this.accidentalFieldsTarget.classList.remove('hide');
    this.justificationTarget.required = true;
  }
}
