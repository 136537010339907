import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['notProspectiveFields', 'stoppageReason'];

  prospectiveStoppage() {
    this.notProspectiveFieldsTarget.classList.add('hide');
    this.stoppageReasonTarget.required = false;
  }

  notProspectiveStoppage() {
    this.notProspectiveFieldsTarget.classList.remove('hide');
    this.stoppageReasonTarget.required = true;
  }
}
