import { MDCDialog } from '@material/dialog';
import { constructSelect } from './content';

const targets = document.querySelectorAll('a[data-dialog]');

if (targets.length) {
  const pageLocator = document.body.classList[0];
  const dialog = new MDCDialog(document.querySelector('.mdc-dialog'));
  const titleContent = document.getElementById('dialog-title');
  const dialogContent = document.getElementById('dialog-content');

  targets.forEach((modal) => {
    modal.addEventListener('click', function (e) {
      e.preventDefault();
      titleContent.innerHTML = '';
      dialogContent.innerHTML = decider(pageLocator, modal);
      dialog.open();
    });
  });

  const decider = (page, modal) => {
    switch (page) {
      case 'constructs_selections':
        return constructSelect[modal.textContent];
      case 'lists-strategies':
        return constructSelect[modal.textContent];
    }
  };
}
