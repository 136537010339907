export const constructSelect = {
  'CFIR 2.0':
    'Consolidated Framework for Implementation Research, 2.0: Determinants framework that comprises 5 domains: Innovation, Outer Setting, Inner Setting, Individuals, and Implementation Process.',
  EPIS: 'Exploration, Preparation, Implementation, Sustainment: Determinants framework (and process model) that comprises 4 domains: Outer Context, Inner Context, Bridging Factors, and Innovation Factors.',
  PRISM:
    'Practical Implementation Sustainability Model: Determinants framework that comprises 5 domains: Implementation and Sustainability Infrastructure, Organizational Characteristics, Patient Characteristics, Organizational Perspectives on the Intervention, and Patient Perspectives on the Intervention. Linked to the RE-AIM evaluation framework.',
  SEM: 'Socio-Ecological Model: Widely used model with 5 nested domains: Individual, Interpersonal, Organizational, Community, and Societal factors.',
  Individuals:
    'The Individuals Domain encompasses the roles and attributes of individuals involved in the innovation process. This encompasses both those who are directly or indirectly delivering the innovation, as well as those who are directly or indirectly receiving it.',
  Innovation:
    "Innovation refers to the implementation of something new, whether it's a groundbreaking clinical treatment, an innovative educational program, or an enhanced city service.",
  'Inner setting':
    'Inner Setting refers to the specific environment where the innovation is implemented, such as a hospital, school, or city. Within the Inner Setting, there can be multiple levels, like units, classrooms, or teams.',
  'Outer setting':
    'The Outer Setting refers to the environment or context in which the Inner Setting exists. This can include various entities such as a hospital system, school district, or state. Additionally, there can be multiple levels within the Outer Setting, such as the community, system, or state.',
  'Implementation process':
    'The implementation process encompasses the activities and strategies employed to introduce and integrate the innovation. This can involve the selection of deliverers for the implementation team, as well as the involvement of recipients who can contribute to the implementation team or participate in the innovation itself.',
  'Innovation factors':
    'Innovation factors are closely tied to the characteristics of the planned implementation. These factors encompass the traits of the innovation developers, the innovation itself, and its alignment with the system, organization, provider, and/or client.',
  'Outer context':
    'The outer context refers to the external environment of the organization. This encompasses the service and policy environment, as well as the characteristics of the individuals who are the recipients of evidence-based practice (e.g., patients, consumers).',
  'Inter-connections, interactions, linkages, relationships':
    'Inter-connections, interactions, linkages, relationships placeholder',
  'Bridging factors':
    'Bridging factors encompass a variety of structures and processes that promote collaboration and connectivity between external and internal constructs. These factors encompass collaborations between communities and academia, evolving connections among policy and practice entities (e.g., government and healthcare providers), diverse sources of influence and guidance (both formal and informal), and the significant contributions of facilitators and intermediaries.',
  'Inner context':
    'The inner context encompasses various organizational aspects, including leadership, organizational structures, resources, policies, staffing, and individual adopter characteristics (e.g., clinicians, practitioners).',
  'Intervention\\Organizational perspective':
    "Organizational perspective encompasses the program or intervention elements, viewed from the lens of the organization and its staff. This encompasses factors such as the organization's preparedness for the program, the robustness of evidence for the clinical target area and proposed implementation strategy, and the program's effectiveness in addressing frontline staff barriers.",
  'Intervention\\Patient perspective':
    'The Patient/Community Perspective encompasses key factors to consider in implementing a successful program. These include patient-centeredness, offering choices, overcoming barriers, ensuring seamless transitions between program elements, addressing service and access issues, minimizing patient burden (i.e., complexity and cost), and collaboratively setting goals and action plans while providing patient feedback.',
  'Recipients\\Organizational characteristics':
    "Organizational characteristics encompass the factors that influence an organization's capacity to effectively modify behaviors within a specific clinical area. These factors should be taken into account across three organizational levels: top management, middle managers, and frontline teams.",
  'Recipients\\Patient characteristics':
    'The domain of patients and community members encompasses various factors that should be taken into account to maximize the effectiveness of interventions and ensure important subgroups are included. When designing interventions, it is crucial to consider key characteristics such as age, gender, socioeconomic status, health literacy, native language, and culture.',
  'External environment':
    'The external environment encompasses various factors, including policies, regulations, financial incentives or disincentives, clinical practice guidelines, and historical considerations. For instance, market forces, such as payor satisfaction, particularly when compared to the competition, can facilitate rapid change.',
  'Implementation and sustainability infrastructure':
    'Implementation and Sustainability Infrastructure refers to a dynamic support structure that plays a crucial role in facilitating the adoption, implementation, and long-term sustainability of interventions. It specifically addresses key issues that significantly impact the continuity of programs, interventions, or implementation strategies.',
  Individual: 'Individual placeholder',
  Organizational: 'Organizational placeholder',
  Interpersonal: 'Interpersonal placeholder',
  Community: 'Community placeholder',
  Societal: 'Societal placeholder',

  'Implementation + Recipient':
    'Outcomes related to innovation delivery (e.g., feasibility, adoption, fidelity, cost, reach) and recipient-level innovation effectiveness (e.g., adherence, health behavior, health status). Drawn from multiple frameworks.',
  'Implementation + Service + Recipient':
    'Outcomes related to innovation delivery (e.g., feasibility, adoption, fidelity, cost, reach); service/quality (e.g., timeliness, efficiency, safety, equitability); and recipient-level innovation effectiveness (e.g., adherence, health behavior, health status). Drawn from multiple frameworks.',
  Recipient:
    'Includes factors such as satsifaction, function, and symptomatology.',
  Implementation:
    'The effects of deliberate and purposive actions to implement new treatments, practices, and services.',
  Service: 'Six domains of healthcare quality.',

  ERIC: 'Expert Recommendations for Implementing Change: Commonly used taxonomy of implementation strategies with 9 groupings: Engage Consumers, Use Evaluative & Iterative Strategies, Change Infrastructure, Adapt & Tailor to Context, Develop Stakeholder Interrelationships, Utilize Financial Strategies, Support Clinicians, Provide Interactive Assistance, and Train & Educate Stakeholders.',
  'Support clinicians': 'Support clinicians placeholder',
  'Adapt and tailor to context': 'Adapt and tailer to context placeholder',
  'Use evaluative and iterative strategies':
    'Use evaluative and iterative strategies placeholder',
  'Engage consumers': 'Engage consumers placeholder',
  'Train and educate stakeholders':
    'Train and educate stakeholders placeholder',
  'Provide interactive assistance':
    'Provide interactive assistance placeholder',
  'Utilize financial strategies': 'Utilize financial strategies placeholder',
  'Develop stakeholder interrelationships':
    'Develop stakeholder interrelationships placeholder',
  'Change infrastructure': 'Change infrastructure placeholder',

  'COM-B': 'COM-B placeholder',
  Capacity: 'Capacity placeholder',
  Motivation: 'Motivation placeholder',
  Need: 'Need placeholder',
  Opportunity: 'Opportunity placeholder'
};
